import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// import VueApexCharts from "vue3-apexcharts";


//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initAutoAnimate } from "@/core/plugins/autoanimate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.use(store);
app.use(router);

app.use(ElementPlus as any);

// app.use(VueApexCharts);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();
initAutoAnimate(app);

app.mount("#app");
