import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;
// const apiUrl = window.location.origin;

function buildUrl(url) {
    return apiUrl + url;
}

export default {
    post(path, payload) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .post(url, payload)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    get(path) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },
    put(path, payload) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .post(url, payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },
    delete(path) {
        const token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common.Authorization = token;
        }
        const url = buildUrl(path);
        return axios
            .delete(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    },

    geoGet(path) {
        const url = "https://api.countrystatecity.in/v1/" + path;
        axios.defaults.headers.common = {
            "X-CSCAPI-KEY": "V3ZqYmE3NnlneFhNTjFUa2kyTUplYjR5S1V4YlplMUNrd0VON3NKQg==",
        };
        return axios
            .get(url, {
                transformRequest: [(data, headers) => {
                    delete axios.defaults.headers.common["X-CSCAPI-KEY"];
                }]
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => error);
    }
};
