import { App } from "vue";
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

/**
 * Initialize Auto Animate component
 * @param app vue instance
 */
export function initAutoAnimate(app: App<Element>) {
  app.use(autoAnimatePlugin);
}
