
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import axios from "axios";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "app",
    setup() {
        const store = useStore();
        const router = useRouter();

        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        });

        return { store, router };
    },
    // created() {
    //     console.log("app created");

    //     axios.interceptors.response.use(
    //         (response) => {
    //             if (response.status === 401) {
    //                 //add your code
    //             }
    //             return response;
    //         },
    //         (error) => {
    //             if (error.response && error.response.data) {
    //                 //add your code
    //                 this.store.dispatch(Actions.LOGOUT);
    //                 this.router.push({ name: "sign-in" });

    //                 return Promise.reject(error.response.data);
    //             }
    //             return Promise.reject(error.message);
    //         }
    //     );
    // },
});
