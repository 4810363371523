import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import MembersApi from "@/core/services/MembersApi";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard/overview",
        component: () => import("@/layout/Layout.vue"),
        beforeEnter: (to, from, next) => {
            store
                .dispatch(Actions.VERIFY_AUTH)
                .then(() => {
                    next();
                })
                .catch(() => {
                    router.push({ name: "sign-in" });
                });
        },
        children: [
            {
                path: "/dashboard/overview",
                name: "dashboard-overview",
                component: () => import("@/views/Dashboard/Dashboard.vue"),
            },
            {
                path: "/dashboard/statistics",
                name: "dashboard-statistics",
                component: () => import("@/views/Statistics.vue"),
            },
            {
                path: "/dashboard/join-now",
                name: "join-now",
                component: () => import("@/views/NewJoinNow.vue"),
            },
            {
                path: "/member/add/",
                name: "member-add",
                component: () => import("@/views/AddMember.vue"),
            },
            {
                path: "/member/:member_id/p=:page?/:editDueId?/:editPromotionId?",
                name: "member-details",
                component: () => import("@/views/MemberDetails.vue"),
            },
            {
                path: "/members/list",
                name: "members-list",
                component: () => import("@/views/MembersList.vue"),
            },
            {
                path: "/members/archives",
                name: "members-archive",
                component: () => import("@/views/MembersArchive.vue"),
            },
            {
                path: "/promotions",
                name: "promotions",
                component: () => import("@/views/Promotions.vue"),
            },
            {
                path: "/dues/",
                name: "dues",
                component: () => import("@/views/Dues.vue"),
            },
            {
                path: "/events",
                name: "events",
                component: () => import("@/views/EventsList.vue"),
            },
            {
                path: "/event/:event_id?/",
                name: "event-details",
                component: () => import("@/views/EventDetails.vue"),
            },
            {
                path: "/reservations",
                name: "reservations",
                component: () => import("@/views/ReservationsList.vue"),
            },
            {
                path: "/reservation/:reservation_id/",
                name: "reservation-details",
                component: () => import("@/views/ReservationDetails.vue"),
            },
            {
                path: "/chapitres",
                name: "chapitres",
                component: () => import("@/views/Chapitres.vue"),
            },
            {
                path: "/chapitre/:chapitre_id/:mode?",
                name: "chapitre-details",
                component: () => import("@/views/ChapitreDetails.vue"),
            },
            {
                path: "/chapitre/:mode?",
                name: "chapitre-add",
                component: () => import("@/views/ChapitreDetails.vue"),
            },
            {
                path: "/accr",
                name: "accr",
                component: () => import("@/views/ACCR.vue"),
            },
            {
                path: "/emailing",
                name: "emailing",
                component: () => import("@/views/Emailing.vue"),
            },
            {
                path: "/emailing/campaigns",
                name: "campaigns",
                component: () => import("@/views/CampaignList.vue"),
            },
            {
                path: "/emailing/campaigns/details/:campaign_id/",
                name: "campaign-details",
                component: () => import("@/views/CampaignDetails.vue"),
            },
            {
                path: "/emailing/campaigns/add/",
                name: "campaign-add",
                component: () => import("@/views/CampaignDetails.vue"),
            },
            {
                path: "/emailing/segments",
                name: "segments",
                component: () => import("@/views/SegmentList.vue"),
            },
            {
                path: "/emailing/segments/details/:segment_id/",
                name: "segment-details",
                component: () => import("@/views/SegmentDetails.vue"),
            },
            {
                path: "/emailing/segments/add/",
                name: "segment-add",
                component: () => import("@/views/SegmentDetails.vue"),
            },
            {
                path: "/accounting",
                name: "accounting",
                component: () => import("@/views/Accounting.vue"),
            },
            {
                path: "/accounting/biller/:biller_id/:biller_name?",
                name: "biller-details",
                component: () => import("@/views/BillerDetails.vue"),
            },
            {
                path: "/repositories",
                name: "repositories",
                component: () => import("@/views/Repositories.vue"),
            },
            {
                path: "/repositories/:repo_path/:repo_name?",
                name: "repo-details",
                component: () => import("@/views/RepoDetails.vue"),
            },
            {
                path: "/users",
                name: "users",
                component: () => import("@/views/Users.vue"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () =>
                    import("@/views/authentication/SignIn.vue"),
            },
            {
                path: "/sign-up/:token",
                name: "sign-up",
                component: () =>
                    import("@/views/authentication/SignUp.vue"),
            },
            {
                path: "/change-password",
                name: "change-password",
                component: () =>
                    import("@/views/authentication/PasswordReset.vue"),
            },
            {
                path: "/reset-password/:token",
                name: "reset-password",
                component: () => import("@/views/authentication/ChangePassword.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/Error500.vue"),
    },
    {
        path: "/pro-form/:token",
        name: "pro-form",
        component: () => import("@/views/ProForm.vue"),
    },
    {
        path: "/member-card/:member_id",
        name: "member-card",
        component: () => import("@/views/MemberCard.vue"),
    },
    {
        path: "/member-update/:member_id",
        name: "member-update",
        component: () => import("@/views/MemberUpdate.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from) => {
    // page title
    if (process.env.VUE_APP_API_URL === "http://51.138.120.205/")
        document.title = "[STAGING] CDR"
    else if (process.env.VUE_APP_API_URL.includes("localhost"))
        document.title = "[LOCAL] CDR"

    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
    if (to && to !== undefined && to.name && (to.name as any)) {
        if (
            to.name !== 'sign-in'
            && to.name !== 'change-password'
            && to.name !== 'reset-password'
            && to.name !== 'sign-up'
            && to.name !== 'pro-form'
            && to.name !== 'member-card'
            && to.name !== 'member-update'
        ) {
            store
                .dispatch(Actions.GET_USER)
                .then(() => {
                    if (!store.getters.isUserAuthenticated) {
                        console.log('not authenticated');
                        
                        router.push({ name: "sign-in" });
                        return false
                    } else {
                        if (localStorage.getItem('id_token')) {
                            const token_infos = JwtService.parseToken(localStorage.getItem("id_token"));
                            localStorage.setItem('perm_pos', token_infos.perm_position)

                            if (parseInt(localStorage.getItem('perm_pos') as any) >= 2) {
                                localStorage.setItem('bailliage', token_infos.national_bailliage)
                                MembersApi.getSpecificBailliage(token_infos.national_bailliage).then(res => {
                                    localStorage.setItem('bailliage_label', res.data.label)
                                });
                            }

                            if (token_infos.perm_position === 5 && to.name !== 'member-update') {
                                router.push({ name: "500" });
                                return false
                            }

                        }
                    }
                })
                .catch(() => {                    
                    router.push({ name: "sign-in" });
                    return false
                });
            return true;
        }
    }
    return true
});


export default router;
