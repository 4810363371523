import apiTool from "./index";
import ApiService from "./ApiService";

const LOGIN_URL = "api/v1/user/sign-in/";
const REGISTER_URL = "api/v1/user/sign-up/";
const GET_USER_URL = "api/v1/user/get";
const GET_USER_BY_ID_URL = "api/v1/user/get/<pk>";
const FORGET_PASSWORD_URL = "api/v1/user/forgot-password/";
const CHANGE_PASSWORD_URL = "api/v1/user/change-password/";

export default {
    login(payload) {
        return ApiService.post(LOGIN_URL, payload).then(response => {
            return response;
        });
    },
    async getUserById(userId) {
        ApiService.setHeader();
        return ApiService.get(GET_USER_BY_ID_URL.replace("<pk>", userId)).then(response => {
            return response.data;
        })
    },
    getUser() {
        ApiService.setHeader();
        return ApiService.get(GET_USER_URL).then(response => {
            return response.data;
        })
    },
    register(payload) {
        return apiTool.post(REGISTER_URL, payload);
    },
    forgetPassword(payload) {
        return apiTool.post(FORGET_PASSWORD_URL, payload);
    },
    changePassword(payload) {
        return apiTool.post(CHANGE_PASSWORD_URL, payload);
    },
};
